@keyframes wobble {
  0% {
    transform: scale(1) rotate(0);
  }
  10% {
    transform: scale(0.9) rotate(-10deg);
  }
  20% {
    transform: scale(0.9) rotate(10deg);
  }
  30% {
    transform: scale(1.1) rotate(-10deg);
  }
  40% {
    transform: scale(1.1) rotate(10deg);
  }
  50% {
    transform: scale(1.1) rotate(-5deg);
  }
  60% {
    transform: scale(1.1) rotate(5deg);
  }
  70% {
    transform: scale(1.1) rotate(-3deg);
  }
  80% {
    transform: scale(1.1) rotate(3deg);
  }
  90% {
    transform: scale(1.1) rotate(-1deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.wobble {
  animation: wobble 1s;
}
